import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ExternalHtmlProps {
	html: string;
	className?: string;
}

const ExternalHtml: React.FC<ExternalHtmlProps> = (props) => {
	const { className = 'external-html', html } = props;
	const navigate = useNavigate();
	const handleClick = (e) => {
		const link = e.target.tagName === 'A' ? e.target : e.target.closest('a');

		if (link?.href && link?.target !== '_blank') {
			e.preventDefault();
			const url = new URL(link.href);
			if (url.host === location.host) {
				navigate({
					pathname: url.pathname,
					search: url.search,
				});
			} else {
				const a = document.createElement('a');
				a.href = link;
				a.click();
			}
		}
	};

	return <div
		onClick={handleClick}
		className={className}
		dangerouslySetInnerHTML={{ __html: html }}
	/>;
};

export default ExternalHtml;
