import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { imageSize } from '@common/react/utils/utils';

import { ethnicities } from '@commonTuna/react/components/UI/CaseModalInner/CaseModalInner';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import { Case } from '@commonTuna/react/objects/BaseCase';
import { Gender } from '@commonTuna/react/objects/Enums';

import { capitalizeFirstLetter } from '@app/components/Utils';

import '@app/scss/components/beforeAfter.scss';

interface ComponentProps {
	item: Case;
}

const BeforeAfter = (props: ComponentProps) => {
	const { t, i18n } = useTranslation();

	const { item } = props;

	const lang = capitalizeFirstLetter(i18n.language);

	return <div className="single-case">
		<div className="single-case__photos">
			<div className="single-case__photos_left">
				<ImageWithSkeleton
					src={imageSize(item.before, '_main')}
					alt="Before"
					withLazy
					loaderContainerClassName="ba-image__loader fa fa-file-image-o"
				/>
			</div>
			<div className="single-case__photos_right">
				<ImageWithSkeleton
					src={imageSize(item.after, '_main')}
					alt="After"
					withLazy
					loaderContainerClassName="ba-image__loader fa fa-file-image-o"
				/>
			</div>
		</div>
		<div className="single-case__body">
			<div className="single-case__title">
				{`#${item.indexInCompany} `}
				{item[`name${lang}`]}
			</div>
			<div className="row row-short entity-container">
				<div className="col-sm-6">
					<label className="param-label">
						{t('forms.Gender')}
						:
					</label>
					{Gender[item.gender]}
				</div>
				<div className="col-sm-6">
					<label className="param-label">
						{t('forms.Ethnicity')}
						:
					</label>
					{item.ethnicity ? ethnicities[item.ethnicity[`name${lang}`].toLowerCase()] || item.ethnicity[`name${lang}`] : 'N/A'}
				</div>
			</div>
			<div className="row row-short">
				<div className="col-sm-4">
					<label className="param-label">
						{t('forms.Age')}
						:
					</label>
					{item.age || 'N/A'}
					<span className="mobile-hidden">
						&nbsp;
						{item.age ? lang === 'En' ? 'year(s)' : t('forms.Age') : ''}
					</span>
				</div>
				<div className="col-sm-4">
					<label className="param-label">
						{t('forms.Weight')}
						:
					</label>
					{item.weight || 'N/A'}
					<span className="mobile-hidden">&nbsp;</span>
					{item.weight ? 'lb' : ''}
				</div>
				<div className="col-sm-4">
					<label className="param-label">
						{t('forms.Height')}
						:
					</label>
					{item.height || 'N/A'}
					<span className="mobile-hidden">&nbsp;</span>
					{item.height ? 'ft' : ''}
				</div>
			</div>
		</div>
	</div>;
};

export default BeforeAfter;
