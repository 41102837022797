import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick-adaptive-height-fixed';

import 'slick-carousel/slick/slick.css';

import { getBackgroundUrl } from '@common/typescript/Utils';
import { Lang } from '@common/typescript/objects/Lang';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';
import ExternalHtml from '@common/react/components/UI/ExternalHtml/ExternalHtml';

import {
	Anesthesia, FactsBlock, Page, PageImage, PageVideo,
} from '@commonTuna/react/objects/Page';
import { CasePage } from '@commonTuna/react/objects/BaseCase';

import BeforeAfter from '@app/components/UI/BeforeAfter/BeforeAfter';
import {
	capitalizeFirstLetter, reviewsSliderSettings, sliderSettings, getPageInfo, getPageShortName,
} from '@app/components/Utils';
import '@app/scss/pages/service.scss';
import ServiceFaqs from '@app/components/Pages/Service/ServiceFaqs';
import ServiceCard from '@app/components/Pages/Service/ServiceCard';
import LockContent from '@app/components/UI/LockContent/LockContent';
import DoctorSlider from '@app/components/Pages/Home/DoctorSlider';
import ContactUsForm from '@app/components/Forms/ContactUs/ContactUsForm';
import '@app/scss/components/slick-theme.scss';
import Reviews from '@app/components/UI/Reviews/Reviews';
import { SpecialsInner } from '@app/components/Pages/Specials/Specials';

interface ComponentProps {
	page: any;
}

const Iframe: React.FC<{ url }> = ({ url }) => {
	const [loading, setLoading] = React.useState(true);
	const [visible, setVisible] = React.useState(false);
	const [error, setError] = React.useState(false);
	const { t } = useTranslation();
	const ref = React.useRef<HTMLDivElement | null>(null);
	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setVisible(true);
				}
			}, {
				rootMargin: '20px',
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, [ref.current]);

	return <div ref={ref} className="frame-wrapper is-relative" style={{ minHeight: 100 }}>
		<iframe
			title={url}
			src={visible ? url : undefined}
			onLoad={() => {
				console.log('onLoad');
				setLoading(false);
			}}
			allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			onError={() => {
				setError(true);
				setLoading(false);
			}}
		/>
		{(loading || error) && <div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.2)',
			}}
		>
			{error ? <h3>{t('site.Failed to load')}</h3> : <Loader />}
		</div>}
	</div>;
};

const Service = (props: ComponentProps) => {
	const { page } = props;
	const {
		children, videos, faqs, files, cases, randomReviews, specials,
	} = page.item;

	const { t, i18n } = useTranslation();
	const langName: 'Es' | 'En' = capitalizeFirstLetter(i18n.language);
	const pageInfo = getPageInfo(page, Lang[langName]);

	const AnesthesiaNames = {
		[Anesthesia.None]: t('site.None'),
		[Anesthesia.General]: t('site.General'),
		[Anesthesia.Sedation]: t('site.Sedation'),
		[Anesthesia.Regional]: t('site.Regional'),
		[Anesthesia.Local]: t('site.Local'),
	};

	const list = [
		{
			value: page.item[`purpose${langName}`],
			name: t('site.What can id do'),
		},
		{
			value: AnesthesiaNames[page.item.anesthesia],
			name: t('site.Anesthesia'),
		},
		{
			value: page.item.fromPages.length && page.item.fromPages.map((page) =>
				<a href={`/${langName === 'Es' ? page.toPage.fullPathEs : page.toPage.fullPath}`} key={page.id}>
					{page.toPage[`shortName${langName}`]}
				</a>),
			name: t('site.Combine with'),
			className: 'combine-list',
		},
		{
			value: page.item.surgeryLength,
			name: t('site.Operation length'),
		},
		{
			value: page.item.recoveryTime,
			name: t('site.Recovery Time'),
		},
	].filter((item) => item.value);

	const factsPlacementLeft = page.item.factsBlock === FactsBlock.Left;

	return <div className="service-page">
		{files.length > 0 && <div className="service-page__slider">
			<Slider {...sliderSettings}>
				{files.map((file: PageImage) => <div key={file.id} className="single-slide">
					<div
						className="single-slide__image"
						style={{ backgroundImage: `${getBackgroundUrl(file.file.src)}, ${getBackgroundUrl(file.file.thumb)}` }}
					/>
					<div className="container">
						<div className="single-slide__body">
							<div className="single-slide__title">{file[`title${langName}`]}</div>
							{file[`text${langName}`] && <hr />}
							<div className="single-slide__description">{file[`text${langName}`]}</div>
						</div>
					</div>
				</div>)}
			</Slider>
		</div>}
		<div className="container" style={{ paddingTop: files.length > 0 ? undefined : 25 }}>
			{pageInfo && <div className="service-page__text service-page__block">
				<div className="service-page__headline inner-page__headline text-center">
					<h1>{getPageShortName(page.item, Lang[langName])}</h1>
				</div>
				{page.item?.[`tagLine${langName}`] && <div className="tagline">
					<h2>{page.item?.[`tagLine${langName}`]}</h2>
				</div>}
				<div className="clearfix">
					{list.length > 1 && page.item.factsBlock !== FactsBlock.None
						? (
							<div
								className={`fact-block external-html pull-${
									factsPlacementLeft ? 'left' : 'right'}`}
							>
								<ul
									className={`service-page__block-bordered placement-${
										factsPlacementLeft ? 'left' : 'right'}`}
								>
									{list.map(({ value, name, className }) => (
										<li className={className} key={name}>
											<span>
												{name}
												:
											</span>
											{value}
										</li>
									))}
								</ul>
							</div>
						) : null
					}
					<ExternalHtml html={pageInfo.body} />
				</div>
			</div>}
			{children && children.list.length > 0 ? <div className="sub-pages__line" /> : null}
			{children && children.list.length > 0 && <div className="sub-pages service-page__block row">
				{children.list.map((child: Page) => <ServiceCard key={child.id} page={child} />)}
			</div>}
			{page.item.doctors?.length > 0
				? (<div className="row">
					<div className="service-page__title">
						<h3>{t('site.Providers')}</h3>
					</div>
					<DoctorSlider
						doctors={page.item.doctors}
						className="service-page__block doctor-card-list"
					/>
				</div>
				) : null}
			<ServiceFaqs
				initialItems={faqs}
				pageId={page.id}
				langName={langName}
			/>
			{videos.length > 0 && <>
				<div className="service-page__title">
					<h3>{t('site.Videos')}</h3>
				</div>
				<div className="service-page__videos service-page__block row">
					{videos.map((video: PageVideo) => <div key={video.id} className={videos.length > 1 ? 'col-sm-6' : 'col-sm-12'}>
						<Iframe url={video.url} />
					</div>)}
				</div>
			</>}
			{specials.length > 0 ? <>
				<div className="service-page__title">
					<h3>{t('site.Specials')}</h3>
				</div>
				<SpecialsInner asSlider items={{ list: specials.slice(0, 3) }} />
			</> : null}
			{cases.length > 0 && <div className="service-page__cases service-page__block text-center is-relative">
				<LockContent key="lock-content" />
				<div className="service-page__title">
					<h3>{t('site.Before and after')}</h3>
				</div>
				<div className="cases-wrapper mobile-hidden">
					{cases.slice(0, 3).map((item: CasePage, index: number) => <React.Fragment key={index}>
						<BeforeAfter item={item.case} />
					</React.Fragment>)}
				</div>
				<div className="cases-wrapper mobile-show">
					<Slider {...reviewsSliderSettings}>
						{cases.slice(0, 3).map((item: CasePage, index: number) => <BeforeAfter item={item.case} key={item.id} />)}
					</Slider>
				</div>
				<LinkWithLang to={`/beforeafter?pageIds=[${page.id}]`} className="general-form-button">{t('forms.Find More')}</LinkWithLang>
			</div>}
			<Reviews reviews={randomReviews} />
			<div className="contacts-page__form">
				<div className="inner-page__headline text-center">
					<h2>{t('site.Schedule an Appointment Now')}</h2>
				</div>
				<ContactUsForm className="general-form" pageId={page.item.id} />
			</div>
		</div>
	</div>;
};

export default Service;
