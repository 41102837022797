import React from 'react';

import { useTranslation } from 'react-i18next';

import { useMobileView } from '@common/react/hooks/useMobileView';

import { Doctor } from '@commonTuna/react/objects/BaseDoctor';

import DoctorCard, { IsInvalidDoctorCard } from '@app/components/Pages/Home/DoctorCard';
import SliderWithObserver from '@app/components/UI/SliderWithObserver/SliderWithObserver';
import { capitalizeFirstLetter } from '@app/components/Utils';

interface Props {
	doctors: Array<Doctor>;
	className?: string;
}

const DoctorSlider: React.FC<Props> = ({ doctors, className = 'home-page-gifts__item' }) => {
	const { i18n } = useTranslation();
	const langString: 'En' | 'Es' = capitalizeFirstLetter(i18n.language);
	const isMobile = useMobileView(600);

	const giftsSlickSettings = React.useMemo(() => {
		return {
			slidesToShow: doctors.length > 3 ? 3 : doctors.length,
			autoplaySpeed: 4000,
			lazyLoad: false,
			autoplay: !isMobile,
			adaptiveHeight: isMobile,
			className: 'home-page-gifts',
			arrows: true,
			dots: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: doctors.length > 2 ? 2 : doctors.length,
						rows: 1,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		};
	}, [doctors.length, isMobile]);

	return <SliderWithObserver
		key={isMobile ? 'mobile' : 'desktop'}
		sliderProps={giftsSlickSettings}
		className="slider-arrows doctor-card-list doctors-slider"
	>
		{
			doctors.filter((item) => !IsInvalidDoctorCard(item, langString))
				.map((item) =>
					<div key={item.id} className={className}>
						<DoctorCard item={item} className="col-sm-12" />
					</div>)
		}
	</SliderWithObserver>;
};

export default DoctorSlider;
