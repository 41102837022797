import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { ItemsProvider } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import LoadMoreButton from '@common/react/components/Core/ItemsProvider/LoadMoreButton';
import { List } from '@common/typescript/objects/List';

import { FaqPage } from '@commonTuna/react/objects/FaqPage';

import CollapseService from '@app/components/Pages/Faq/CollapseService';

interface ServiceFaqsProps {
	initialItems: List<FaqPage>;
	pageId: number;
	langName: string;
}

const ServiceFaqs: React.FC<ServiceFaqsProps> = ({
	initialItems,
	pageId,
	langName,
}) => {
	const { t } = useTranslation();

	return (
		<ItemsProvider
			items={initialItems.list.map((item) => item.faq)}
			pagination={{ total: initialItems.count }}
			type="faq"
			loadRequest="faqViewListRemote"
			filters={{
				pageId,
				count: 10,
			}}
		>
			{({ state: { items } }) => items?.length > 0 && <div className="service-page__faq service-page__block">
				<div className="service-page__title">
					<h3>FAQ</h3>
				</div>
				<CollapseService items={items} langName={langName} />
				<div className="text-center">
					<LoadMoreButton
						className="general-form-button general-form-button_sm"
					>
						{t('site.Load More')}
					</LoadMoreButton>
				</div>
			</div>}
		</ItemsProvider>
	);
};

export default ServiceFaqs;
