import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { Lang } from '@common/typescript/objects/Lang';
import { ItemProvider, useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { BreadCrumbs } from '@common/react/components/UI/BreadCrumbs/BreadCrumbs';
import ServerPageProvider from '@common/react/components/UI/lang/SeverPageProviderWithLang/ServerpageProviderWithLang';
import ExternalHtml from '@common/react/components/UI/ExternalHtml/ExternalHtml';

import { Page, PagePage, PageType } from '@commonTuna/react/objects/Page';

import Service from '@app/components/Pages/Service/Service';
import ShareLinks from '@app/components/UI/ShareLinks/ShareLinks';
import { getPageInfo, getPageShortName } from '@app/components/Utils';

interface SectionProps {
	item?: Page;
}

const SectionInner: React.FC = () => {
	const { state: { item } } = useItemProviderContext<any>();

	const { t, i18n } = useTranslation();
	const lang = i18n.language === 'es' ? Lang.Es : Lang.En;

	const service = item?.item;

	const pages: Array<PagePage> = service?.pages?.list || [];
	const info = getPageInfo(item, lang);
	const name = getPageShortName(service, lang);
	const isService = service?.pageType === PageType.SERVICE;

	return <div className="inner-page page-with-special">
		<div className={`container is-relative clearfix ${isService ? '' : 'section-page'}`}>
			<BreadCrumbs
				className={isService ? 'on-slide' : 'pull-left'}
				links={pages.filter((page) => page.parent.fullPath)
					.map((page) => ({
						title: getPageShortName(page.parent, lang),
						path: `/${lang === Lang.Es ? page.parent.fullPathEs : page.parent.fullPath}`,
					}))}
				title={name}
			/>
			<ShareLinks />
		</div>
		{isService
			? <Service page={item} />
			: <div className="container">
				<div className="inner-page__headline text-center">
					<h1>{name}</h1>
				</div>
				<ExternalHtml html={info ? info.body : ''} />
			</div>
		}
	</div>;
};

const Section: React.FC<SectionProps> = ({
	item,
}) => {
	const location = useLocation();
	const { actions: { load } } = useItemProviderContext<any>();
	const [skipLoad, setSkipLoad] = React.useState(true);
	const { getLang } = useApplicationContext();
	const lang = getLang();

	React.useEffect(() => {
		const newPath = lang === Lang.Es ? `/es/${item?.fullPathEs}`
			: `/${item?.fullPath === 'home' ? '' : item?.fullPath}`;
		if (!skipLoad && newPath !== location.pathname) {
			load();
		}
		setSkipLoad(false);
	}, [location.pathname]);

	return (
		<ItemProvider
			id={item?.id || 0}
			key={item?.id || 0}
			type="page"
			additionalParams={{ fullPath: location.pathname, withPages: true, withChildren: true }}
			loadRequest="getPageRemote"
			item={item}
			skipInitLoad={!!item}
		>
			<SectionInner />
		</ItemProvider>
	);
};

const SectionWrapper: React.FC = () => {
	return <ServerPageProvider
		inner={(serverPage) => <Section item={serverPage} />}
	/>;
};

export default SectionWrapper;
